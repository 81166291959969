// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    defaultTheme: "myCustomTheme",
    themes: {
      myCustomTheme: {
        dark: false,
        colors: {
          background: "#ffffff",
          "background-darken-1": "#fafbff",
          surface: "#ffffff",
          primary: "#93e4c1",
          "primary-darken-1": "#3baea0",
          "primary-darken-2": "#118a7e",
          "primary-darken-3": "#1f6f78",
          error: "#f32347",
          info: "#2196f3",
          success: "#4caf50",
          warning: "#fb8c00",
          "grey-light": "#c4c4c4",
        },
      },
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
