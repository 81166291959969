const LangStore = {
  namespaced: true,
  state: {
    lang: "en",
  },
  getters: {
    getLanguage(context) {
      context.get("getLanguage");
    },
  },
  mutations: {
    setLanguage(state, language) {
      state.lang = language;
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit("setLanguage", language);
    },
  },
};

export default LangStore;
