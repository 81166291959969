const routesName = {
  indexPage: "indexPage",
  aboutPage: "aboutPage",
  contactUsPage: "contactUsPage",
  paymentsPage: "paymentsPage",
  servicesPage: "servicesPage",
  loginPage: "loginPage",
  registerPage: "registerPage",
  forgotPassword: "forgotPassword",
  bookingPage: "bookingPage",

  clientServices: "clientServices",
  clientMasters: "clientMasters",
  clientDiscount: "clientDiscount",

  adminUserView: "adminUserView",

  dashboard: "dashboard",
  requests: "requests",

  setting: "setting",
  feedback: "feedback",
  vouchers: "vouchers",

  sms: "sms",

  privacyPolicy: "privacyPolicy",
  offer: "offer",
};

export default routesName;
