<template>
  <v-card class="mb-5" variant="plain">
    <v-list-item class="w-100 d-flex justify-center">
      <v-menu>
        <template v-slot:activator="{ props }">
          <div
            v-bind="props"
            class="d-flex cursor-pointer align-center"
            :class="rail ? 'flex-column' : ''"
          >
            <user-card-info
              :avatar="user.avatar"
              :first-name="user.first_name"
              :last-name="user.last_name"
              :subtitle="user.role_name"
              :show-name-info="!rail"
            />
            <v-list-item-action>
              <v-btn
                v-if="!rail"
                variant="plain"
                :icon="
                  props['aria-expanded'] === 'false'
                    ? 'mdi-chevron-up'
                    : 'mdi-chevron-down'
                "
                size="small"
              />
            </v-list-item-action>
          </div>
        </template>
        <v-list>
          <v-list-item
            elevation="1"
            rounded
            v-for="(item, index) in dropDownItems"
            :key="index"
            :value="index"
            :prepend-icon="item.icon"
            :title="$t(item.title)"
            @click="handleFunctionCall(item.method)"
          />
        </v-list>
      </v-menu>
    </v-list-item>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import routesName from "@/router/routesName";
import UserCardInfo from "@/components/common/UserCardInfo";
import { ref } from "vue";

export default {
  name: "MenuUserInfo",
  components: { UserCardInfo },
  props: ["rail"],
  setup() {
    const dropDownItems = ref([
      {
        title: "form.buttons.privacyPolicy",
        icon: "mdi-shield-lock-outline",
        method: "privacyPolicy",
      },
      {
        title: "form.buttons.logout",
        icon: "mdi-exit-to-app",
        method: "logout",
      },
    ]);

    return { routesName, dropDownItems };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    handleFunctionCall(functionName) {
      this[functionName]();
    },
    logout() {
      this.logoutAction().then((response) => {
        if (response.status === 204) {
          this.$router.push({ name: routesName.loginPage });
        }
      });
    },
    privacyPolicy() {
      this.$router.push({ name: routesName.privacyPolicy });
    },
  },
};
</script>
