import ApiService from "@/services/apiService";

export default class Auth {
  static login(data) {
    return ApiService.post("api/auth/login", data);
  }

  static register(data) {
    return ApiService.post("api/auth/register", data);
  }

  static forgotPassword(data) {
    return ApiService.post("api/auth/forgot-password", data);
  }

  static sendOTP(data) {
    return ApiService.post("api/auth/send-otp", data);
  }

  static checkOTP(data) {
    return ApiService.post("api/auth/check-otp", data);
  }

  static sanctum() {
    return ApiService.get("sanctum/csrf-cookie");
  }

  static logout() {
    return ApiService.delete("api/auth/logout");
  }
}
