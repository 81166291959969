import ApiService from "@/services/apiService";

export default class Profile {
  static updateAvatar(data) {
    return ApiService.post("api/profile/avatar", data);
  }

  static updateProfile(data) {
    return ApiService.put("api/profile/update", data);
  }

  static changePassword(data) {
    return ApiService.post("api/profile/change-password", data);
  }
}
