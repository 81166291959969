<template>
  <div v-if="!isUserOwner" class="d-flex justify-center">
    <v-btn
      @click="toggleFeedbackModal(true)"
      class="text-body-2 mt-2 text-grey-darken-1"
      variant="plain"
    >
      <v-icon v-if="rail">mdi-comment-text-multiple-outline</v-icon>
      <span v-else>{{ $t("pagesName.feedback") }}</span>
    </v-btn>
  </div>
  <feedback-modal />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FeedbackModal from "@/modals/FeedbackModal";

export default {
  name: "FeedbackComponent",
  components: { FeedbackModal },
  props: ["rail"],
  computed: {
    ...mapGetters({
      isUserOwner: "auth/isOwner",
    }),
  },
  methods: {
    ...mapActions({
      toggleFeedbackModal: "modal/toggleFeedbackModal",
    }),
  },
};
</script>
