import ApiService from "@/services/apiService";

export default class Service {
  static getPublicServices(params) {
    return ApiService.get("api/services", { params });
  }

  static getBookingServices() {
    return ApiService.get("api/booking/services");
  }

  static storeService(data) {
    return ApiService.post("api/admin/services", data);
  }

  static updateService(serviceId, data) {
    return ApiService.put(`api/admin/services/${serviceId}`, data);
  }

  static deleteService(serviceId) {
    return ApiService.delete(`api/admin/services/${serviceId}`);
  }
}
