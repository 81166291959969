const LoadingStore = {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    isLoading: (state) => state.loading,
  },
  mutations: {
    toggleLoading(state, data) {
      state.loading = data;
    },
  },
  actions: {
    toggleLoading({ commit }, data) {
      commit("toggleLoading", data);
    },
  },
};

export default LoadingStore;
