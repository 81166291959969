import Master from "@/services/master";

const mastersStore = {
  namespaced: true,
  state: {
    mastersList: [],
    meta: {
      count: 0,
      per_page: 50,
      prev_cursor: "",
      next_cursor: "",
    },
    master: {
      id: 0,
      first_name: "",
      last_name: "",
      level: "",
      gender: "",
      avatar: "",
    },
  },
  getters: {},
  mutations: {
    setMasters(state, response) {
      state.mastersList = response.data;
      if (response.meta) {
        state.meta = response.meta;
      }
    },
    setMaster(state, master) {
      state.master = master;
    },
  },
  actions: {
    getBookingMasters({ commit }, serviceIds) {
      return Master.getBookingMasters(serviceIds).then((response) => {
        commit("setMasters", response.data);
        return response;
      });
    },
  },
};

export default mastersStore;
