<template>
  <v-navigation-drawer
    v-model="drawer"
    :rail="rail"
    elevation="2"
    permanent
    rail-width="80"
    class="position-fixed"
  >
    <v-toolbar-title class="ml-5 mt-5 mb-5">
      <project-logo :with-text="!rail" />
    </v-toolbar-title>
    <v-btn
      class="navigation-drawer-arrow text-grey z-index-10"
      variant="text"
      :icon="rail ? 'mdi-chevron-right' : 'mdi-chevron-left'"
      @click.stop="rail = !rail"
    />

    <v-list nav class="pl-3 pr-3">
      <router-link
        :to="{ name: item.routeName }"
        v-for="(item, index) in getMenuItems"
        :key="index"
      >
        <v-list-item
          :value="index"
          class="text-grey pa-4"
          active-class="active-menu-item"
          :title="!rail ? $t(item.title) : ''"
          :prepend-icon="item.icon"
          :append-icon="
            $route.name === item.routeName || rail ? '' : 'mdi-chevron-right'
          "
          :active="$route.name === item.routeName"
        />
      </router-link>
      <feedback-component :rail="rail" />
    </v-list>

    <template v-slot:append>
      <div class="d-flex justify-center mb-5">
        <locale-switcher :with-text="!rail" />
      </div>
      <div class="d-flex justify-center mb-5">
        <router-link :to="{ name: routesName.bookingPage }" target="_blank">
          <v-btn
            class="pa-5 text-capitalize font-weight-medium"
            size="small"
            color="primary-darken-1"
          >
            <v-icon>mdi-plus</v-icon>
            <span v-if="!rail" class="ml-2">
              {{
                isUserClient
                  ? $t("form.buttons.bookNewAppointment")
                  : $t("form.buttons.onlineBooking")
              }}
            </span>
          </v-btn>
        </router-link>
      </div>
      <menu-user-info :rail="rail" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import ProjectLogo from "@/components/common/ProjectLogo";
import MenuUserInfo from "@/components/common/MenuUserInfo";
import routesName from "@/router/routesName";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import { mapGetters } from "vuex";
import FeedbackComponent from "@/components/common/FeedbackComponent";

export default {
  name: "UserNavigation",
  components: { FeedbackComponent, LocaleSwitcher, MenuUserInfo, ProjectLogo },
  inject: ["isMobile"],
  setup() {
    return { routesName };
  },
  data() {
    return {
      drawer: true,
      clientItems: [
        {
          title: "pagesName.dashboard",
          icon: "mdi-monitor-dashboard",
          routeName: routesName.dashboard,
        },
        {
          title: "pagesName.services",
          icon: "mdi-cube-scan",
          routeName: routesName.clientServices,
        },
        {
          title: "pagesName.masters",
          icon: "mdi-account-box-outline",
          routeName: routesName.clientMasters,
        },
        {
          title: "pagesName.discount",
          icon: "mdi-sale",
          routeName: routesName.clientDiscount,
        },
        {
          title: "pagesName.setting",
          icon: "mdi-cog-outline",
          routeName: routesName.setting,
        },
      ],
      staffItems: [
        {
          title: "pagesName.dashboard",
          icon: "mdi-monitor-dashboard",
          routeName: routesName.dashboard,
        },
        {
          title: "pagesName.requests",
          icon: "mdi-calendar-multiselect",
          routeName: routesName.requests,
        },
        {
          title: "pagesName.setting",
          icon: "mdi-cog-outline",
          routeName: routesName.setting,
        },
      ],
      adminItems: [
        {
          index: 0,
          title: "pagesName.dashboard",
          icon: "mdi-monitor-dashboard",
          routeName: routesName.dashboard,
        },
        {
          index: 1,
          title: "pagesName.requests",
          icon: "mdi-calendar-multiselect",
          routeName: routesName.requests,
        },
        {
          index: 2,
          title: "pagesName.services",
          icon: "mdi-cube-scan",
          routeName: routesName.clientServices,
        },
        {
          index: 3,
          title: "pagesName.masters",
          icon: "mdi-account-box-outline",
          routeName: routesName.clientMasters,
        },
        {
          index: 4,
          title: "pagesName.setting",
          icon: "mdi-cog-outline",
          routeName: routesName.setting,
        },
      ],
      ownerItems: [
        {
          index: 3,
          title: "pagesName.vouchers",
          icon: "mdi-ticket-percent-outline",
          routeName: routesName.vouchers,
        },
        {
          index: 5,
          title: "pagesName.feedback",
          icon: "mdi-comment-text-multiple-outline",
          routeName: routesName.feedback,
        },
        {
          index: 0,
          title: "pagesName.sms",
          icon: "mdi-chat-outline",
          routeName: routesName.sms,
        },
      ],
      rail: this.isMobile,
    };
  },
  watch: {
    "$vuetify.display.mdAndDown"(newValue) {
      this.rail = newValue;
    },
  },
  computed: {
    ...mapGetters({
      isUserClient: "auth/isClient",
      isUserStaff: "auth/isStaff",
      isUserAdmin: "auth/isAdmin",
      isUserOwner: "auth/isOwner",
    }),
    getMenuItems() {
      if (this.isUserClient) {
        return this.clientItems;
      } else if (this.isUserStaff) {
        return this.staffItems;
      } else if (this.isUserAdmin) {
        return this.adminItems;
      } else if (this.isUserOwner) {
        return this.adminItems
          .concat(this.ownerItems)
          .sort((a, b) => a.index - b.index);
      }
      return [];
    },
  },
};
</script>
