import Auth from "@/services/auth";
import Profile from "@/services/profile";
import userType from "@/constants/userType";

const authStore = {
  namespaced: true,
  state: {
    user: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      role_name: "",
      level: "",
      gender: "",
      is_available_online: false,
      date_of_birth: "",
      avatar: "",
      note: "",
      website: "",
      permissions: [],
    },
    token: {
      access: "",
      type: "Bearer",
    },
  },
  getters: {
    userInitials: (state) =>
      `${state.user.first_name[0]}. ${state.user.last_name[0]}.`,
    userFullName: (state) => `${state.user.first_name} ${state.user.last_name}`,
    isUserLoggedIn: (state) => state.token.access,
    isClient: (state) => state.user.role_name === userType.client,
    isStaff: (state) => state.user.role_name === userType.staff,
    isAdmin: (state) => state.user.role_name === userType.administrator,
    isOwner: (state) => state.user.role_name === userType.owner,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token.access = token.access_token;
      state.token.type = token.token_type;
    },
  },
  actions: {
    sanctum() {
      return Auth.sanctum();
    },
    login({ commit }, data) {
      return Auth.login(data).then((response) => {
        commit("setUser", response.data.data.user);
        commit("setToken", response.data.data.token);
        return response;
      });
    },
    register({ commit }, data) {
      return Auth.register(data).then((response) => {
        if (response.status === 201) {
          commit("setUser", response.data.data.user);
          commit("setToken", response.data.data.token);
        }
        return response;
      });
    },
    changePassword({ commit }, data) {
      return Profile.changePassword(data).then((response) => {
        commit("setUser", {});
        commit("setToken", {});
        return response;
      });
    },
    updateAvatar({ commit }, data) {
      return Profile.updateAvatar(data).then((response) => {
        commit("setUser", response.data.data);
        return response;
      });
    },
    updateProfile({ commit }, data) {
      return Profile.updateProfile(data).then((response) => {
        commit("setUser", response.data.data);
        return response;
      });
    },
    // eslint-disable-next-line no-unused-vars
    forgotPassword({ commit }, data) {
      return Auth.forgotPassword(data);
    },
    // eslint-disable-next-line no-unused-vars
    sendOTP({ commit }, data) {
      return Auth.sendOTP(data);
    },
    // eslint-disable-next-line no-unused-vars
    checkOTP({ commit }, data) {
      return Auth.checkOTP(data);
    },
    logout({ commit }) {
      return Auth.logout().then((response) => {
        commit("setUser", {});
        commit("setToken", {});
        return response;
      });
    },
    removeAuthUser({ commit }) {
      commit("setUser", {});
      commit("setToken", {});
    },
  },
};

export default authStore;
