import { createI18n } from "vue-i18n";
import enMessages from "@/locales/en";
import uaMessages from "@/locales/ua";

const locale = JSON.parse(localStorage.getItem("vuex"))
  ? JSON.parse(localStorage.getItem("vuex")).lang.lang
  : "uk";
const i18n = createI18n({
  availableLocales: ["en", "uk"],
  fallbackLocale: "uk",
  locale,
  messages: {
    en: enMessages,
    uk: uaMessages,
  },
});

export default i18n;
