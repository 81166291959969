import ApiService from "@/services/apiService";

export default class Master {
  static getBookingMasters(serviceIds) {
    return ApiService.post("api/booking/masters", {
      service_ids: serviceIds,
    });
  }

  static getMasters(params) {
    return ApiService.get("api/masters", { params });
  }

  static getAdminMasters() {
    return ApiService.get("api/admin/masters");
  }
}
