import { createRouter, createWebHistory } from "vue-router";
import routesName from "./routesName";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: routesName.indexPage,
    component: () =>
      import(/* webpackChunkName: "public" */ "@/views/LandingView.vue"),
    meta: {
      hasHeader: true,
      public: true,
    },
  },
  {
    path: "/privacy-policy",
    name: routesName.privacyPolicy,
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/information/PrivacyPolicyView.vue"
      ),
    meta: {
      hasHeader: true,
      public: true,
    },
  },
  {
    path: "/offer",
    name: routesName.offer,
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/information/OfferView.vue"
      ),
    meta: {
      hasHeader: true,
      public: true,
    },
  },
  {
    path: "/contact-us",
    name: routesName.contactUsPage,
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/information/ContactUsView.vue"
      ),
    meta: {
      hasHeader: true,
      public: true,
    },
  },
  {
    path: "/payments",
    name: routesName.paymentsPage,
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/information/PaymentView.vue"
      ),
    meta: {
      hasHeader: true,
      public: true,
    },
  },
  {
    path: "/about-us",
    name: routesName.aboutPage,
    component: () =>
      import(
        /* webpackChunkName: "public" */ "@/views/information/AboutUsView.vue"
      ),
    meta: {
      hasHeader: true,
      public: true,
    },
  },
  {
    path: "/available-services",
    name: routesName.servicesPage,
    component: () =>
      import(/* webpackChunkName: "public" */ "@/views/ServicesView.vue"),
    meta: {
      hasHeader: true,
      public: true,
    },
  },
  {
    path: "/booking",
    name: routesName.bookingPage,
    component: () =>
      import(/* webpackChunkName: "public" */ "@/views/BookingView.vue"),
    meta: {
      public: true,
    },
  },
  {
    path: "/login",
    name: routesName.loginPage,
    component: () =>
      import(/* webpackChunkName: "public" */ "@/views/LoginView"),
    meta: {
      public: true,
    },
  },
  {
    path: "/register",
    name: routesName.registerPage,
    component: () =>
      import(/* webpackChunkName: "public" */ "@/views/RegisterView"),
    meta: {
      public: true,
    },
  },
  {
    path: "/forgot-password",
    name: routesName.forgotPassword,
    component: () =>
      import(/* webpackChunkName: "public" */ "@/views/ForgotPasswordView"),
    meta: {
      public: true,
    },
  },
  {
    path: "/dashboard",
    name: routesName.dashboard,
    component: () =>
      import(/* webpackChunkName: "client" */ "@/views/DashboardView"),
  },
  {
    path: "/services",
    name: routesName.clientServices,
    component: () =>
      import(
        /* webpackChunkName: "client" */ "@/views/client/ClientServicesView"
      ),
  },
  {
    path: "/masters",
    name: routesName.clientMasters,
    component: () =>
      import(
        /* webpackChunkName: "client" */ "@/views/client/ClientMastersView"
      ),
  },
  {
    path: "/masters/:id",
    name: routesName.adminUserView,
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/AdminUserView"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/discount",
    name: routesName.clientDiscount,
    component: () =>
      import(
        /* webpackChunkName: "client" */ "@/views/client/ClientDiscountView"
      ),
  },
  {
    path: "/setting",
    name: routesName.setting,
    component: () =>
      import(/* webpackChunkName: "client" */ "@/views/SettingView"),
  },
  {
    path: "/vouchers",
    name: routesName.vouchers,
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/AdminVoucherView"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/sms",
    name: routesName.sms,
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/AdminSmsView"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/feedback",
    name: routesName.feedback,
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/AdminFeedbackView"),
    meta: {
      admin: true,
    },
  },
  {
    path: "/requests",
    name: routesName.requests,
    component: () =>
      import(/* webpackChunkName: "staff" */ "@/views/RequestsView"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = store.state.auth.token;
  const isUserAdmin =
    store.getters["auth/isAdmin"] || store.getters["auth/isOwner"];
  if (!to.matched.some((record) => record.meta.public) && !token.access) {
    return next({
      name: routesName.loginPage,
    });
  }
  if (to.matched.some((record) => record.meta.admin) && !isUserAdmin) {
    return next({
      name: routesName.dashboard,
    });
  }
  next();
});

export default router;
