const ua = {
  landing: {
    firstBlock: {
      title: "Зробіть їх.",
      title2: "Зробіть їх яскравими.",
      second:
        "У нас є кращі з кращих фахівців, які зроблять цукерки з ваших нігтів.",
      buttonText: "Записатись зараз",
    },
    howItWorks: "Ось як це працює:",
    servicesBlock: {
      title: "Послуги",
      second:
        "Спочатку ви обираєте послугу, яку хочете зробити для ваших красивих нігтів.",
      buttonText: "Всі послуги",
    },
    mastersBlock: {
      title: "Майстри",
      second:
        "Далі ви вибираєте зі списку майстра, якому потрібно запланувати. У списку ви побачите лише тих майстрів, які можуть виконати вибрану вами послугу.",
    },
    datesTimesBlock: {
      title: "Дата та Час",
      second:
        "І, нарешті, ви побачите всі дати, доступні для запису, після вибору дати - ви побачите всі доступні проміжки часу, на які ви можете запланувати.",
    },
  },
  aboutUs: {
    introduction: {
      title: "Ми - студія нігтьової естетики {appName} 🫶",
      content:
        "{appName} - це про любов до своїх клієнтів. Більше року ми приділяємо особливу увагу якості та безпеці наших послуг, а також професійному зростанню наших фахівців. Ми дотримуємося всіх 4 етапів дезінфекції та стерилізації манікюрного інструменту. Тому ви можете бути абсолютно спокійними приходячи на процедуру.",
    },
    whyUs: {
      title: "Чому саме ми? 🤔",
      cards: {
        experience: {
          title: "Досвід",
          content: {
            first: "Більше",
            last: "задоволених клієнтів",
          },
          action:
            "Адже ми використовуємо лише якісну сертифіковану продукцію в своїй роботі",
        },
        time: {
          title: "Час",
          content: {
            first: "Манікюр або педикюр за",
            last: "⁣",
            counter: {
              suffix: "хв",
            },
          },
          action:
            "Ми цінуємо ваш час, тому у нас є можливість зробити манікюр, або педикюр лише за 60 хв",
        },
        security: {
          title: "Безпека",
          content: {
            first: "⁣",
            last: "стерилізація",
            counter: {
              suffix: "%",
            },
          },
          action:
            "Гарантія стерильності і безпеки всіх процедур та інструментів",
        },
      },
    },
    bonusProgram: {
      title: "Бонусна програма 🎁",
      content:
        "Маємо для Вас дві крутецькі бонусні програми, якими Ви можете користуватись у своєму профілі користувача",
      cards: {
        voucher: {
          title: "Ваучери",
          action:
            "Унікальні коди, забравши який, Ви зможете отримати знижку на процедуру, або ще багато чого цікавого",
        },
        discount: {
          title: "Дисконтна карта",
          action:
            "Ми ще не придумали, як саме виглядатиме наша дисконтна програма, але це буде те, що вразить Вашу уяву 🤯",
        },
      },
    },
  },
  pagesName: {
    services: "Послуги",
    about: "Про нас",
    contactUs: "Зв'язатись з нами",
    signIn: "Увійти",
    signUp: "Зареєструватись",
    onlineBooking: "Онлайн запис",
    forgotPassword: "Забули пароль",
    dashboard: "Панель",
    masters: "Майстри",
    discount: "Знижки",
    setting: "Налаштування",
    myDetails: "Моя інформація",
    changePassword: "Зміна паролю",
    requests: "Запити",
    information: "Інформація",
    schedule: "Розклад",
    privacyPolicy: "Політика Конфіденційності",
    feedback: "Зворотній Зв'язок",
    vouchers: "Ваучери",
    discountCard: "Дисконтна карта",
    companyInfo: "Інформація про компанію",
    help: "Допомога",
    payment: "Оплата",
    offer: "Оферта",
    sms: "SMS",
  },
  landingServices: {
    categories: "Категорії",
    selectCategories: "Виберіть категорію",
  },
  signIn: {
    helloFriend: "Привіт Друже!",
    enterInfo: "Введіть свої особисті дані та почніть подорож разом з нами",
    buttonSignUp: "Зареєструватись",
    signInToAppName: "Увійти до {appName}",
  },
  register: {
    welcomeBack: "З поверненням!",
    loginInfo:
      "Щоб залишатися на зв'язку з нами, будь ласка, увійдіть, використовуючи свою особисту інформацію",
    buttonSignIn: "Увійти",
    createAccount: "Створення акаунту",
    enterOtpCode: "Введіть код підтвердження",
  },
  forgotPassword: {
    backToSignIn: "Повернутись до логіну",
    forgotQuestion: "Забули пароль?",
  },
  dashboard: {
    hello: "Привіт, {username} 👋",
  },
  dateTime: {
    duration: "{hours}г {minutes}хв",
  },
  appointment: {
    date: "Дата",
    time: "Час",
    price: "Ціна",
    services: "Послуги",
    histories: "Історія",
    master: "Майстер",
    details: "Деталі зустрічі",
    endingAt: "закінчиться в {time}",
    receipt: {
      title: "Квитанція",
      value: "Сума",
      discount: "Знижка",
      totalAmount: "Загальна сума",
    },
    note: {
      title: "Нотатка",
    },
    cancelled: "Зустріч відмінено",
    rescheduled: "Зустріч перенесено",
    modal: {
      create: "Створення нової зустрічі",
      client: "Деталі клієнта",
      addService: "Додати послугу",
    },
  },
  services: {
    autocompletePlaceholder: "Почніть вводити назву",
    autocompleteLabel: "Пошук сервісу...",
    categories: "Категорії",
    categoriesModal: {
      title: "Список категорій",
    },
    serviceModal: {
      editTitle: "Редагування послуги",
      createTitle: "Створення послуги",
    },
    createdSuccessfully: "Послугу створено успішно!",
    updatedSuccessfully: "Послугу відредаговано успішно!",
    deletedSuccessfully: "Послугу видалено успішно!",
  },
  discount: {
    title: "Зараз ми розробляємо щось прекрасне",
    subtitle: "Незабаром ми запустимо нашу дисконтну програму",
    alert:
      "Зараз знижка дійсна тільки в День Вашого Народження. Введіть свій День Народження в Налаштуваннях",
  },
  masters: {
    autocompletePlaceholder: "Почніть вводити ім'я майстра",
    autocompleteLabel: "Пошук майстра...",
    type: "Тип",
    createModal: {
      title: "Створення нового майстра",
    },
    createdSuccessfully: "Користувача створено успішно!",
    updatedSuccessfully: "Користувача оновлено успішно!",
    avatarUpdatedSuccessfully: "Аватар користувача оновлено успішно!",
  },
  booking: {
    aboutYou: "04. Про вас",
    services: "01. Послуги",
    masters: "02. Майстри",
    datesTimes: "03. Дата та час",
    finish: "05. Інфо",
    steps: "Крок",
    description: {
      aboutYou: {
        title: "Введіть інформацію про себе",
        hint:
          "Ваше ім’я та номер телефону використовуватимуться для підтвердження зустрічей та нагадувань. " +
          "Ми також зможемо зателефонувати або надіслати вам повідомлення, якщо щось зміниться.",
      },
      services: {
        selectService: "Оберіть послугу",
      },
      masters: {
        selectMaster: "Виберіть майстра з списку",
      },
      datesTimes: {
        selectDatesTimes: "Виберіть дату та час",
        morning: {
          title: "Ранок",
          description: "08:00 - 12:00",
        },
        midday: {
          title: "Обід",
          description: "12:00 - 17:00",
        },
        evening: {
          title: "Вечір",
          description: "17:00 - 20:00",
        },
      },
      finish: {
        title: "Зустріч заброньовано успішно 🎉",
      },
    },
  },
  requests: {
    status: "Статус",
    master: "Майстер",
    date: "Дата",
    time: "Час",
    dateTime: "Дата і час",
    created: "Створено",
    updated: "Оновлено",
    comment: "Текст",
    infoComment: "Якщо текст занадто довгий - натисніть на нього",
    action: "Дія",
    deleteModal: {
      title: "Видалити Запит",
      hint: "Ви збираєтеся видалити запит",
    },
    rejectModal: {
      title: "Відхилити Запит",
      hint: "Ви збираєтеся відхилити запит",
    },
    approveModal: {
      title: "Схвалити Запит",
      hint: "Ви збираєтеся схвалити запит",
    },
    createModal: {
      title: "Створення запиту",
      comment: "Коментар",
      from: "Час з",
      to: "Час по",
    },
    createdSuccessfully: "Запит створено успішно",
    deletedSuccessfully: "Запит видалено успішно",
    rejectedSuccessfully: "Запит відхилено успішно",
    approvedSuccessfully: "Запит схвалено успішно",
  },
  schedule: {
    title: "Редагування розкладу",
    createTitle: "Створення розкладу",
    type: "Тип розкладу",
    typeHint: "Виберіть тип створення, тільки на 1 день чи не період",
    date: "Дата",
    repeatThisTill: "Повторювати до",
    fromTime: "Початок роботи",
    toTime: "Кінець роботи",
    updatedSuccessfully: "Розклад користувача оновлено успішно!",
    createdSuccessfully: "Розклад користувача створено успішно!",
    deletedSuccessfully: "Розклад користувача видалено успішно!",
  },
  categories: {
    name: "Ім'я",
    order: "Впорядкування",
    action: "Дія",
    orderTooltip:
      "У якому порядку будуть відображатися категорії при онлайн-бронюванні",
    deletedSuccessfully: "Категорія видалена успішно!",
    editedSuccessfully: "Категорія відредагована успішно!",
    storedSuccessfully: "Категорія створена успішно!",
  },
  settings: {
    updated: "Оновлено успішно",
    previewPrivacyPolicy: "Предперегляд Політики Конфіденційності",
    privacyPolicyUpdated: "Політика Конфіденційності успішно оновлена!",
  },
  feedback: {
    title: "Зворотній зв'язок",
    hint: "Тут Ви можете залишити побажання або пропозиції щодо покращення функцій сайту",
    created:
      "Дякуємо за Ваш відгук! Найближчим часом ми його розглянемо і приймемо в роботу!",
    radios: {
      watched: "Переглянуті",
      noWatched: "Не переглянуті",
    },
    fullName: "Повне ім'я",
    comment: "Коментар",
  },
  vouchers: {
    noExpirationDate: "Без закінчення",
    alreadyUsed: "Вже використано",
    modal: {
      create: {
        title: "Створення ваучера",
        successfully: "Ваучер створено успішно!",
      },
      info: {
        users: "Користувачі",
        deletedSuccessfully: "Ваучер видалено успішно!",
        makeUsedSuccessfully: "Ваучер користувача відмічено як використаний!",
        updatedSuccessfully: "Ваучер відредаговано успішно",
        copied: "Скопійовано!",
      },
      redeem: {
        title: "Погашення коду",
        redeemSuccessfully: "Вітаємо 👏! Ви встигли забрати ваучер!",
      },
    },
  },
  otp: {
    modal: {
      title: "Підтвердження номера телефону",
    },
  },
  payment: {
    cash: {
      title: "Готівкою",
      description:
        "Оплата проводиться тільки в національній валюті безпосередньо при відвідуванні салону.",
    },
    cardOnWebsite: {
      title: "Карткою на вебсайті",
      description:
        "Ви можете оплатити послуги банківською карткою Visa або MasterCard на сайті.",
      additionalInfo:
        "Під час оформлення послуг на сайті Вам буде запропоновано зробити вибір способу оплати. У графі 'Оплата' вам потрібно вибрати 'Оплата карткою на сайті'. Після цього Ви переадресуєте на сторінку системи безпечних платежів банку, де Вам необхідно буде підтвердити оплату.",
    },
    cardInSalon: {
      title: "Карткою в салоні",
      description:
        "Ви можете оплатити послуги банківською карткою Visa або MasterCard в салоні.",
      additionalInfo:
        "Під час оформлення послуг на сайті Вам буде запропоновано зробити вибір способу оплати. У графі 'Оплата' вам потрібно вибрати 'Оплата карткою в салоні'. Після цього Вам необхідно взяти банківську картку із собою до салону для оплати ваших послуг.",
    },
  },
  form: {
    comingSoon: {
      title: "Зовсім скоро",
    },
    firstLastNames: {
      title: "Ім'я та Прізвище",
      hint: "Василь Пупкін",
    },
    firstName: {
      title: "Ім'я",
      hint: "Василь",
    },
    lastName: {
      title: "Прізвище",
      hint: "Пупкін",
    },
    gender: {
      title: "Стать",
      hint: "Виберіть стать із списку",
    },
    role: {
      title: "Роль",
      hint: "Виберіть роль із списку",
    },
    phoneNumber: {
      title: "Номер телефону",
      hint: "+380 (99) 999-99-99",
      description:
        "Коли Ви змінюєте номер телефону, вам потрібно підтвердити новий номер",
    },
    email: {
      title: "Email",
      hint: "email@email.com",
      description:
        "Поле не обов'язкове, але ви можете його ввести, ми будемо відправляти Вам новини",
    },
    otpCode: {
      title: "Код верифікації",
      hint: "22222",
      description: "Введіть код, який був відправлений Вам на телефон",
    },
    password: {
      title: "Пароль",
      oldTitle: "Старий Пароль",
      newTitle: "Новий Пароль",
      hint: "Принаймні 8 символів",
      ruleTitle: "Повинен містити",
      rules: {
        leastCharacters: "Принаймні 8 символів",
        oneUpper: "Принаймні 1 велика літера (A..Z)",
        oneNumber: "Принаймні 1 цифра (0..9)",
      },
    },
    passwordConfirmation: {
      title: "Підтвердити пароль",
      hint: "Введіть такий же пароль ще раз",
    },
    dateOfBirth: {
      title: "День Народження",
      hint: "Виберіть ваш День Народження",
    },
    appointmentNote: {
      title: "Нотатка",
      hint: "При введенні нотатки не перевищуйте 100 символів",
    },
    level: {
      title: "Рівень",
      hint: "Виберіть рівень майстра",
    },
    availableOnline: {
      title: "Дозволити запис онлайн",
      hint: "Вказує чи можуть клієнти записатись до майстра онлайн",
    },
    website: {
      title: "Вебсайт",
      hint: "Введіть лише дійсні сайти. Instagram, Facebook, TikTok тощо.",
    },
    service: {
      title: "Назва",
    },
    image: {
      title: "Зображення",
      newTitle: "Нове зображення",
    },
    category: {
      title: "Категорія",
    },
    duration: {
      title: "Тривалість",
    },
    price: {
      title: "Ціна",
    },
    masters: {
      title: "Майстри",
    },
    description: {
      title: "Опис",
    },
    comment: {
      title: "Коментар",
    },
    code: {
      title: "Код",
      hint: "####-####",
    },
    redemptions: {
      title: "К-сть Погашень",
    },
    expiredAt: {
      title: "Дійсний до",
    },
    buttons: {
      signUp: "Зареєструватись",
      forgotPassword: "Забули пароль?",
      signIn: "Увійти",
      resetPassword: "Скинути пароль",
      continue: "Продовжити",
      delete: "Видалити",
      cancel: "Закрити",
      apply: "Застосувати",
      create: "Створити",
      finish: "Завершити",
      back: "Назад",
      sendOtp: "Відправити код",
      confirm: "Підтвердити",
      addToGoogleCalendar: "Додати в Google Calendar",
      logout: "Вийти",
      bookNewAppointment: "Забронювати зустріч",
      cancelAppointment: "Відмінити запис",
      save: "Зберегти",
      onlineBooking: "Онлайн Бронювання",
      makeRequest: "Зробити запит",
      yes: "Так",
      no: "Ні",
      approve: "Затвердити",
      reject: "Відхилити",
      createCategory: "Створити категорію",
      edit: "Редагувати",
      privacyPolicy: "Політика Конфіденційності",
      send: "Відправити",
      redeem: "Ввести код",
      take: "Забрати",
      help: "Допомога",
      information: "Інформація",
    },
  },
  validation: {
    required: "Обов'язкове поле",
    notValidDate: "Вибрана дата не правильна",
    counterMax: "Максимум {counter} символів",
    counterMin: "Мінімум {counter} символів",
    phoneNumberFormat: "Повинен бути у форматі +380 (99) 999-99-99",
    firstLastNames: "Повинен бути у форматі **** ****",
    samePassword: "Пароль підтвердження має бути таким же",
    siteUrl: "Не правильна адреса сайту",
  },
  calendar: {
    week: "Тиждень",
    day: "День",
    createAppointment: "Створити зустріч",
    master: "Майстер",
  },
};

export default ua;
