<template>
  <v-avatar v-if="avatar" :size="size ?? 50" :image="avatar.original_url" />
  <v-icon v-else :size="size ?? 'x-large'">mdi-account</v-icon>

  <v-list-item-header class="ml-3" v-if="showNameInfo ?? true">
    <v-list-item-title class="font-weight-medium">
      {{ `${firstName} ${lastName}` }}
    </v-list-item-title>
    <v-list-item-subtitle class="custom-font">
      {{ subtitle }}
    </v-list-item-subtitle>
  </v-list-item-header>
</template>

<script>
export default {
  name: "UserCardInfo",
  props: [
    "size",
    "avatar",
    "firstName",
    "lastName",
    "subtitle",
    "showNameInfo",
  ],
};
</script>
