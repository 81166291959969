import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import Maska from "maska/dist/maska";
import { loadFonts } from "./plugins/webfontloader";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import VueToast from "vue-toast-notification";
import VOtpInput from "vue3-otp-input";
import { SetupCalendar } from "v-calendar";
import "vue-toast-notification/dist/theme-sugar.css";
import "v-calendar/dist/style.css";
import "./scss/app.scss";

loadFonts().then(() => {});

const application = createApp(App)
  .component("otp-input", VOtpInput)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(VueToast)
  .use(Maska)
  .use(createMetaManager())
  .use(metaPlugin)
  .use(SetupCalendar, {});

application.config.unwrapInjectedRef = true;

export const app = application.mount("#app");
