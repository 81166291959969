import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import servicesStore from "@/store/modules/services";
import authStore from "@/store/modules/auth";
import mastersStore from "@/store/modules/masters";
import ModalStore from "@/store/modules/modal";
import LangStore from "@/store/modules/lang";
import LoadingStore from "@/store/modules/loading";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    lang: LangStore,
    auth: authStore,
    services: servicesStore,
    masters: mastersStore,
    modal: ModalStore,
    loading: LoadingStore,
  },
  plugins: [
    createPersistedState({
      paths: ["auth", "lang"],
    }),
  ],
});
