<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      class="z-index-9999"
    >
      <v-list dense class="ml-4 mr-4 mt-4 text-capitalize">
        <div class="d-flex justify-center">
          <locale-switcher />
        </div>
        <v-divider class="mt-3" />
        <v-list-item class="mt-3">
          <router-link :to="{ name: routesName.servicesPage }">
            {{ $t("pagesName.services") }}
          </router-link>
        </v-list-item>
        <v-divider class="mt-3" />
        <p class="mt-3">{{ $t("pagesName.companyInfo") }}</p>
        <v-list-item>
          <router-link :to="{ name: routesName.aboutPage }">
            {{ $t("pagesName.about") }}
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: routesName.privacyPolicy }">
            {{ $t("pagesName.privacyPolicy") }}
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: routesName.offer }">
            {{ $t("pagesName.offer") }}
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: routesName.contactUsPage }">
            {{ $t("pagesName.contactUs") }}
          </router-link>
        </v-list-item>
        <v-divider class="mt-3" />
        <p class="mt-3">{{ $t("pagesName.help") }}</p>
        <v-list-item>
          <router-link :to="{ name: routesName.paymentsPage }">
            {{ $t("pagesName.payment") }}
          </router-link>
        </v-list-item>
        <v-divider class="mt-3" />
        <v-list-item class="mt-3 d-flex justify-center">
          <router-link :to="{ name: routesName.loginPage }">
            <v-btn
              elevation="3"
              color="primary-darken-1"
              class="rounded-pill bg-primary-darken-1 text-white text-capitalize"
            >
              {{ $t("pagesName.signIn") }}
            </v-btn>
          </router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar color="white" class="mt-0 pt-0">
      <project-logo />
      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        v-if="isMobile"
      ></v-app-bar-nav-icon>
      <div
        v-else
        class="navigation-buttons d-inline-flex align-center gap-20 text-body-1 font-weight-medium"
      >
        <locale-switcher />
        <router-link :to="{ name: routesName.servicesPage }">
          {{ $t("pagesName.services") }}
        </router-link>
        <v-menu class="text-capitalize">
          <template v-slot:activator="{ props }">
            <p v-bind="props" class="cursor-pointer text-grey-light">
              {{ $t("form.buttons.information") }}
            </p>
          </template>
          <v-list class="pa-4">
            <p class="pa-2">{{ $t("pagesName.companyInfo") }}</p>
            <v-list-item>
              <router-link :to="{ name: routesName.aboutPage }">
                {{ $t("pagesName.about") }}
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link :to="{ name: routesName.privacyPolicy }">
                {{ $t("pagesName.privacyPolicy") }}
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link :to="{ name: routesName.offer }">
                {{ $t("pagesName.offer") }}
              </router-link>
            </v-list-item>
            <v-list-item>
              <router-link :to="{ name: routesName.contactUsPage }">
                {{ $t("pagesName.contactUs") }}
              </router-link>
            </v-list-item>
            <v-divider />
            <p class="pa-2">{{ $t("pagesName.help") }}</p>
            <v-list-item>
              <router-link :to="{ name: routesName.paymentsPage }">
                {{ $t("pagesName.payment") }}
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
        <span class="primary-darken-1">|</span>
        <router-link :to="{ name: routesName.loginPage }">
          <v-btn
            elevation="3"
            class="rounded-pill bg-primary-darken-1 text-white text-capitalize"
          >
            {{ $t("pagesName.signIn") }}
          </v-btn>
        </router-link>
      </div>
    </v-toolbar>
  </v-container>
</template>

<script>
import routesName from "@/router/routesName";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import ProjectLogo from "@/components/common/ProjectLogo";
import { ref } from "vue";

export default {
  name: "HeaderComponent",
  components: { ProjectLogo, LocaleSwitcher },
  inject: ["isMobile"],
  setup() {
    const drawer = ref(false);

    return { routesName, drawer };
  },
};
</script>

<style scoped></style>
