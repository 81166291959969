import Service from "@/services/service";

const servicesStore = {
  namespaced: true,
  state: {
    servicesList: [],
    categoriesList: [],
    meta: {
      count: 0,
      per_page: 50,
      prev_cursor: "",
      next_cursor: "",
    },
    service: {
      id: 0,
      name: "",
      category_id: 0,
      is_available_online: false,
      duration: 0,
      price: 0,
      description: "",
    },
  },
  getters: {},
  mutations: {
    setServices(state, response) {
      state.servicesList = response.data;
      if (response.categories) {
        state.categoriesList = response.categories;
      }
      state.meta = response.meta;
    },
    setCategories(state, response) {
      state.categoriesList = response.data;
    },
    setService(state, service) {
      state.service = service;
    },
  },
  actions: {
    getServices({ commit }, params) {
      return Service.getPublicServices(params).then((response) => {
        commit("setServices", response.data);
        return response;
      });
    },
    getBookingServices({ commit }) {
      return Service.getBookingServices().then((response) => {
        commit("setCategories", response.data);
        return response;
      });
    },
  },
};

export default servicesStore;
