<template>
  <v-dialog :model-value="isOpenFeedbackModal">
    <v-card>
      <v-card-title class="d-flex justify-end">
        <v-icon class="cursor-pointer" @click="() => closeDialog()">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-content class="text-center pt-0">
        <p class="text-h6">{{ $t("feedback.title") }}</p>
        <p class="font-weight-light">{{ $t("feedback.hint") }}</p>
        <div class="d-flex flex-column mt-5">
          <v-form @submit="submit" class="form">
            <div class="text-left">
              <label>
                <b>{{ $t("form.firstLastNames.title") }}</b>
              </label>
              <v-text-field
                variant="outlined"
                color="primary-darken-1"
                density="compact"
                v-model="form.full_name"
                :error-messages="
                  v$.form.full_name.$errors.length
                    ? v$.form.full_name.$errors[0].$message
                    : ''
                "
              />
            </div>
            <div class="text-left">
              <label>
                <b>{{ $t("form.comment.title") }}</b>
              </label>
              <v-textarea
                color="primary-darken-1"
                v-model="form.comment"
                :error-messages="
                  v$.form.comment.$errors.length
                    ? v$.form.comment.$errors[0].$message
                    : ''
                "
                density="compact"
                variant="outlined"
                rows="3"
                no-resize
                required
                counter
              />
            </div>
            <div class="d-flex justify-end">
              <v-btn
                type="submit"
                color="primary-darken-1"
                class="text-capitalize"
              >
                {{ $t("form.buttons.send") }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-content>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength, minLength, required } from "@vuelidate/validators";
import Feedback from "@/services/feedback";

export default {
  name: "FeedbackModal",
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        full_name: "",
        comment: "",
      },
    };
  },
  watch: {
    isOpenFeedbackModal(newValue) {
      if (newValue) {
        this.form.comment = "";
        this.form.full_name = this.isUserLoggedIn
          ? `${this.user.first_name} ${this.user.last_name}`
          : "";
        this.v$.$reset();
      }
    },
  },
  created() {
    if (this.isUserLoggedIn) {
      this.form.full_name = `${this.user.first_name} ${this.user.last_name}`;
    }
  },
  computed: {
    ...mapGetters({
      isUserLoggedIn: "auth/isUserLoggedIn",
    }),
    ...mapState({
      user: (state) => state.auth.user,
      isOpenFeedbackModal: (state) => state.modal.isOpenFeedbackModal,
    }),
  },
  validations() {
    return {
      form: {
        full_name: {
          required: helpers.withMessage(
            this.$t("validation.required"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("validation.counterMin", { counter: 3 }),
            minLength(3)
          ),
        },
        comment: {
          required: helpers.withMessage(
            this.$t("validation.required"),
            required
          ),
          maxLength: helpers.withMessage(
            this.$t("validation.counterMax", { counter: 100 }),
            maxLength(100)
          ),
        },
      },
    };
  },
  methods: {
    ...mapActions({
      toggleFeedbackModal: "modal/toggleFeedbackModal",
    }),
    closeDialog() {
      this.toggleFeedbackModal(false);
    },
    submit() {
      this.v$.$validate();
      if (this.v$.form.$invalid) {
        return;
      }
      Feedback.store(this.form).then((response) => {
        if (response.status === 201) {
          this.toggleFeedbackModal(false);
          this.$toast.success(this.$t("feedback.created"));
        }
      });
    },
  },
};
</script>
