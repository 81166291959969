import ApiService from "@/services/apiService";

export default class Feedback {
  static store(data) {
    return ApiService.post("api/feedback", data);
  }
  static index(params) {
    return ApiService.get("api/admin/feedback", { params });
  }
  static markWatched(id) {
    return ApiService.put(`api/admin/feedback/${id}`);
  }
}
