const en = {
  landing: {
    firstBlock: {
      title: "Get Them Done.",
      title2: "Have more fun.",
      second:
        "We have the best of the best specialists who will make candy out of your nails.",
      buttonText: "Schedule Now",
    },
    howItWorks: "Here's how it works:",
    servicesBlock: {
      title: "Services",
      second:
        "First you choose the service you want to do for your beautiful nails.",
      buttonText: "Show Services",
    },
    mastersBlock: {
      title: "Masters",
      second:
        "Next, you select from the list of the master to whom you want to scheduled. In the list you will see only those masters who can perform the service you have chosen.",
    },
    datesTimesBlock: {
      title: "Date & Time",
      second:
        "And finally, you will see all dates available for recording, after choosing a date - you will see all available time slots for which you can scheduled.",
    },
  },
  aboutUs: {
    introduction: {
      title: "We are a nail aesthetics studio - {appName} 🫶",
      content:
        "{appName} - it's about loving your customers. For more than a year, we have been paying special attention to the quality and safety of our services, as well as to the professional growth of our specialists. We follow all 4 stages of disinfection and sterilization of manicure tools. Therefore, you can be absolutely calm when coming for the procedure.",
    },
    whyUs: {
      title: "Why us? 🤔",
      cards: {
        experience: {
          title: "Experience",
          content: {
            first: "More than",
            last: "satisfied customers",
          },
          action:
            "After all, we use only high-quality and certified products in our work",
        },
        time: {
          title: "Time",
          content: {
            first: "Manicure and pedicure in",
            last: "⁣",
            counter: {
              suffix: "m",
            },
          },
          action:
            "We value your time, so we have the opportunity to do a manicure or pedicure in just 60 minutes",
        },
        security: {
          title: "Safety",
          content: {
            first: "⁣",
            last: "sterilization",
            counter: {
              suffix: "%",
            },
          },
          action:
            "Guarantee of sterility and safety of all procedures and instruments",
        },
      },
    },
    bonusProgram: {
      title: "Bonus program 🎁",
      content:
        "We have two cool bonus programs for you, which you can use in your user profile",
      cards: {
        voucher: {
          title: "Vouchers",
          action:
            "Unique codes, takes which one, you can get a discount on the procedure, or many other interesting things",
        },
        discount: {
          title: "Discount Card",
          action:
            "We haven't figured out exactly what our discount program will look like yet, but it will be something that blows the imagination 🤯",
        },
      },
    },
  },
  pagesName: {
    services: "Services",
    about: "About us",
    contactUs: "Contact us",
    signIn: "Sign In",
    signUp: "Sign Up",
    onlineBooking: "Online Booking",
    forgotPassword: "Forgot Password",
    dashboard: "Dashboard",
    masters: "Masters",
    discount: "Discount",
    setting: "Setting",
    myDetails: "My Details",
    changePassword: "Change Password",
    requests: "Requests",
    information: "Information",
    schedule: "Schedule",
    privacyPolicy: "Privacy Policy",
    feedback: "Feedback",
    vouchers: "Vouchers",
    discountCard: "Discount card",
    companyInfo: "Company Info",
    help: "Help",
    payment: "Payment",
    offer: "Offer",
    sms: "SMS",
  },
  landingServices: {
    categories: "Category",
    selectCategories: "Select categories",
  },
  signIn: {
    helloFriend: "Hello Friend!",
    enterInfo: "Enter your personal details and start journey with us",
    buttonSignUp: "Sign Up",
    signInToAppName: "Sign in to {appName}",
  },
  register: {
    welcomeBack: "Welcome Back!",
    loginInfo: "To keep connected with us please login with your personal info",
    buttonSignIn: "Sign In",
    createAccount: "Create Account",
    enterOtpCode: "Enter verification code",
  },
  forgotPassword: {
    backToSignIn: "Back To Sign In",
    forgotQuestion: "Forgot your password?",
  },
  dashboard: {
    hello: "Hello, {username} 👋",
  },
  dateTime: {
    duration: "{hours}h {minutes}m",
  },
  appointment: {
    date: "Date",
    time: "Time",
    price: "Price",
    services: "Services",
    histories: "Histories",
    master: "Master",
    details: "Appointment Details",
    endingAt: "ending at {time}",
    receipt: {
      title: "Receipt",
      value: "Appointment Value",
      discount: "Discount",
      totalAmount: "Total Amount",
    },
    note: {
      title: "Note",
    },
    cancelled: "Cancelled",
    rescheduled: "Rescheduled",
    modal: {
      create: "Create new appointment",
      client: "Client details",
      addService: "Add service",
    },
  },
  services: {
    autocompletePlaceholder: "Start enter service name",
    autocompleteLabel: "Search service...",
    categories: "Categories",
    categoriesModal: {
      title: "Categories List",
    },
    serviceModal: {
      editTitle: "Edit Service",
      createTitle: "Create Service",
    },
    createdSuccessfully: "Service created successfully!",
    updatedSuccessfully: "Service updated successfully!",
    deletedSuccessfully: "Service deleted successfully!",
  },
  discount: {
    title: "We create awesome stuff for you",
    subtitle: "We are going to launch our discount program soon",
    alert:
      "Now the discount is valid only when it's your birthday. Enter your birthday in Setting",
  },
  masters: {
    autocompletePlaceholder: "Start enter master name",
    autocompleteLabel: "Search master...",
    type: "Type",
    createModal: {
      title: "Create new user",
    },
    createdSuccessfully: "User created successfully!",
    updatedSuccessfully: "User updated successfully!",
    avatarUpdatedSuccessfully: "User avatar updated successfully!",
  },
  booking: {
    aboutYou: "04. About You",
    services: "01. Services",
    masters: "02. Masters",
    datesTimes: "03. Dates & Times",
    finish: "05. Info",
    steps: "Step",
    description: {
      aboutYou: {
        title: "Enter Your Information",
        hint:
          "Your name and phone number will be used to send you appointment confirmations and reminders. " +
          "We’ll also be able to call or text you if anything changes.",
      },
      services: {
        selectService: "Select Service(s)",
      },
      masters: {
        selectMaster: "Select any one master",
      },
      datesTimes: {
        selectDatesTimes: "Select a Date & Time",
        morning: {
          title: "Morning",
          description: "08:00 AM to 12:00 PM",
        },
        midday: {
          title: "Midday",
          description: "12:00 PM to 05:00 PM",
        },
        evening: {
          title: "Evening",
          description: "05:00 PM to 08:00 PM",
        },
      },
      finish: {
        title: "Appointment Booked Successfully 🎉",
      },
    },
  },
  requests: {
    status: "Status",
    master: "Master",
    date: "Date",
    time: "Time",
    dateTime: "Date & Time",
    created: "Created",
    updated: "Updated",
    comment: "Text",
    infoComment: "Click on the text if it is long",
    action: "Action",
    deleteModal: {
      title: "Delete Request",
      hint: "You're about to delete request",
    },
    rejectModal: {
      title: "Reject Request",
      hint: "You're about to reject request",
    },
    approveModal: {
      title: "Approve Request",
      hint: "You're about to approve request",
    },
    createModal: {
      title: "Make Request",
      comment: "Comment",
      from: "From",
      to: "To",
    },
    createdSuccessfully: "Request Created Successfully",
    deletedSuccessfully: "Request Deleted Successfully",
    rejectedSuccessfully: "Request Rejected Successfully",
    approvedSuccessfully: "Request Approved Successfully",
  },
  schedule: {
    title: "Edit Schedule",
    createTitle: "Create Schedule",
    type: "Schedule type",
    typeHint: "One day create or period",
    date: "Date",
    repeatThisTill: "Repeat this till",
    fromTime: "Beginning of work",
    toTime: "End of work",
    updatedSuccessfully: "User schedule updated successfully!",
    createdSuccessfully: "User schedule created successfully!",
    deletedSuccessfully: "User schedule deleted successfully!",
  },
  categories: {
    name: "Name",
    order: "Order",
    action: "Action",
    orderTooltip:
      "In what order will the categories be displayed when booking online",
    deletedSuccessfully: "Category deleted successfully!",
    editedSuccessfully: "Category edited successfully!",
    storedSuccessfully: "Category stored successfully!",
  },
  settings: {
    updated: "Updated Successfully",
    previewPrivacyPolicy: "Preview Privacy Policy",
    privacyPolicyUpdated: "Privacy Policy successfully updated!",
  },
  feedback: {
    title: "Feedback",
    hint: "You can leave your wishes or suggestions for improving the site here",
    created:
      "Thank you for your feedback! We will review it soon and accept it for work!",
    radios: {
      watched: "Watched",
      noWatched: "No Watched",
    },
    fullName: "Full Name",
    comment: "Comment",
  },
  vouchers: {
    noExpirationDate: "No expiration",
    alreadyUsed: "Already used",
    modal: {
      create: {
        title: "Create Voucher",
        successfully: "Voucher created successfully!",
      },
      info: {
        users: "Users",
        deletedSuccessfully: "Voucher deleted successfully!",
        makeUsedSuccessfully: "Voucher user used status changed successfully!",
        updatedSuccessfully: "Voucher updated successfully",
        copied: "Copied!",
      },
      redeem: {
        title: "Redeem code",
        redeemSuccessfully: "Congratulation 👏! The voucher is yours!",
      },
    },
  },
  otp: {
    modal: {
      title: "Confirmation new phone number",
    },
  },
  payment: {
    cash: {
      title: "Cash",
      description:
        "Payment is made only in the national currency directly during the visit salon.",
    },
    cardOnWebsite: {
      title: "By card on the website",
      description:
        "You can pay for services with a Visa or MasterCard bank card on the website.",
      additionalInfo:
        "When ordering services on the website, you will be prompted to choose a payment method. In the 'Payment' column, you need to select 'Payment by card on the website'. After that, you will be redirected to the page of the bank's secure payment system, where you will need to confirm the payment.",
    },
    cardInSalon: {
      title: "By card on the salon",
      description:
        "You can pay for services with a Visa or MasterCard bank card in the salon.",
      additionalInfo:
        "When ordering services on the website, you will be asked to choose a payment method. In the 'Payment' column, you need to select 'Pay by card in the salon'. After that, you need to take your bank card with you to the salon to pay for your services.",
    },
  },
  form: {
    comingSoon: {
      title: "Coming Soon",
    },
    firstLastNames: {
      title: "First & Last Name",
      hint: "John Doe",
    },
    firstName: {
      title: "First Name",
      hint: "John",
    },
    lastName: {
      title: "Last Name",
      hint: "Doe",
    },
    gender: {
      title: "Gender",
      hint: "Select your gender",
    },
    role: {
      title: "Role",
      hint: "Select your role",
    },
    phoneNumber: {
      title: "Phone Number",
      hint: "+380 (99) 999-99-99",
      description:
        "When you change your phone number, you need to confirm the new phone number",
    },
    email: {
      title: "Email",
      hint: "email@email.com",
      description:
        "This field is optional, but you can enter it, we will send you news",
    },
    otpCode: {
      title: "Verification Code",
      hint: "22222",
      description: "Enter the code you received in SMS",
    },
    password: {
      title: "Password",
      oldTitle: "Old Password",
      newTitle: "New Password",
      hint: "At least 8 characters",
      ruleTitle: "Password must contain",
      rules: {
        leastCharacters: "At least 8 characters",
        oneUpper: "At least 1 upper case letter (A..Z)",
        oneNumber: "At least 1 number (0..9)",
      },
    },
    passwordConfirmation: {
      title: "Confirmation Password",
      hint: "Confirmation your password",
    },
    dateOfBirth: {
      title: "Date Of Birth",
      hint: "Select your date of birth",
    },
    appointmentNote: {
      title: "Appointment Note",
      hint: "Do not exceed 100 characters when entering the service description",
    },
    level: {
      title: "Level",
      hint: "Select master level",
    },
    availableOnline: {
      title: "Available Online",
      hint: "Indicates whether the master available for online booking",
    },
    website: {
      title: "Website",
      hint: "Enter only valid sites. Instagram, Facebook, TikTok etc.",
    },
    service: {
      title: "Name",
    },
    image: {
      title: "Image",
      newTitle: "New Image",
    },
    category: {
      title: "Category",
    },
    duration: {
      title: "Duration",
    },
    price: {
      title: "Price",
    },
    masters: {
      title: "Masters",
    },
    description: {
      title: "Description",
    },
    comment: {
      title: "Comment",
    },
    code: {
      title: "Code",
      hint: "####-####",
    },
    redemptions: {
      title: "Redemptions",
    },
    expiredAt: {
      title: "Expired At",
    },
    buttons: {
      signUp: "Sign Up",
      signIn: "Sign In",
      forgotPassword: "Forgot password?",
      resetPassword: "Reset Password",
      continue: "Continue",
      delete: "Delete",
      cancel: "Cancel",
      apply: "Apply",
      create: "Create",
      finish: "Finish",
      back: "Back",
      sendOtp: "Send OTP",
      confirm: "Confirm",
      addToGoogleCalendar: "Add to Google Calendar",
      logout: "Logout",
      bookNewAppointment: "Book New Appointment",
      cancelAppointment: "Cancel Appointment",
      save: "Save",
      onlineBooking: "Online Booking",
      makeRequest: "Make Request",
      yes: "Yes",
      no: "No",
      approve: "Approve",
      reject: "Reject",
      createCategory: "Create Category",
      edit: "Edit",
      privacyPolicy: "Privacy Policy",
      send: "Send",
      redeem: "Redeem",
      take: "Take",
      help: "Help",
      information: "Information",
    },
  },
  validation: {
    required: "Required",
    notValidDate: "Date is not correct",
    counterMax: "Maximum {counter} characters",
    counterMin: "Minimum {counter} characters",
    phoneNumberFormat: "Should be in format +380 (99) 999-99-99",
    firstLastNames: "Should be in format **** ****",
    samePassword: "Confirmation password should be the same",
    siteUrl: "Invalid site url",
  },
  calendar: {
    week: "Week",
    day: "Day",
    createAppointment: "Create Appointment",
    master: "Master",
  },
};

export default en;
