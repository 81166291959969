<template>
  <v-layout>
    <user-navigation />
    <v-main class="bg-background-darken-1">
      <div class="pa-5">
        <router-view />
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import UserNavigation from "@/components/common/UserNavigation";

export default {
  name: "AuthLayout",
  components: { UserNavigation },
};
</script>
