<template>
  <v-app>
    <metainfo>
      <template v-slot:title="{ content }">{{
        content ? `${content} | ${appName}` : appName
      }}</template>
    </metainfo>
    <auth-layout v-if="!isPublic" />
    <v-main v-else>
      <v-container :fluid="isFullPage">
        <header-component v-if="hasHeader" />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderComponent from "@/components/common/Header";
import AuthLayout from "@/layouts/AuthLayout";
import { computed } from "vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { AuthLayout, HeaderComponent },
  data: () => ({
    appName: process.env.VUE_APP_NAME,
  }),
  metaInfo() {
    return { title: "" };
  },
  computed: {
    ...mapGetters({
      isLoading: "loading/isLoading",
    }),
    isFullPage() {
      return this.$route.meta.fullPage ?? true;
    },
    hasHeader() {
      return this.$route.meta.hasHeader ?? false;
    },
    isPublic() {
      return this.$route.meta.public ?? false;
    },
  },
  provide() {
    return {
      isMobile: computed(() => this.$vuetify.display.mdAndDown),
    };
  },
};
</script>
