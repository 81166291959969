<template>
  <router-link
    :to="{ name: getIndexRouteName }"
    class="d-inline-flex align-center text-black text-decoration-none"
  >
    <v-img :src="image" contain height="30" width="30" />
    <span
      class="company-name ml-3 text-no-wrap"
      :class="appNameTextColor"
      v-if="withText ?? true"
    >
      {{ appName }}
    </span>
  </router-link>
</template>

<script>
import image from "@/assets/logo.svg";
import routesName from "@/router/routesName";
import { mapGetters } from "vuex";

export default {
  name: "ProjectLogo",
  props: ["appNameTextColor", "withText"],
  setup() {
    return { routesName, image };
  },
  data: () => ({
    appName: process.env.VUE_APP_NAME,
    drawer: false,
  }),
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isUserLoggedIn",
    }),
    getIndexRouteName() {
      if (this.isLoggedIn) {
        return this.routesName.dashboard;
      }
      return this.routesName.indexPage;
    },
  },
};
</script>
