import axios from "axios";
import { app } from "@/main";
import store from "@/store";
import routesName from "@/router/routesName";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 60 * 1000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const configAuthorized = { ...config };
  configAuthorized.headers["Accept-Language"] = app.$i18n.locale.substring(
    0,
    2
  );
  const token = store.state.auth.token;
  if (token.access && token.type) {
    configAuthorized.withCredentials = true;
    configAuthorized.headers.Authorization = `${token.type} ${token.access}`;
  }

  return configAuthorized;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      const errorMessage = error.response.data.message;
      app.$toast.error(errorMessage);
    } else if (error.response.status === 401) {
      const errorMessage = error.response.data.message;
      app.$toast.error(errorMessage);
      app.$store.dispatch("auth/removeAuthUser");
      app.$router.push({ name: routesName.loginPage });
    } else if (error.response.status === 403) {
      const errorMessage = error.response.data.message;
      app.$toast.error(errorMessage);
    } else if (error.response.status === 422) {
      const errors = error.response.data.errors;
      Object.keys(errors).map((errorKey) => {
        const values = errors[errorKey];
        app.$toast.error(values[0]);
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
