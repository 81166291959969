const ModalStore = {
  namespaced: true,
  state: {
    isOpenCreateRequestModal: false,
    isOpenDeleteRequestModal: false,
    isOpenRejectRequestModal: false,
    isOpenApproveRequestModal: false,
    isOpenAppointmentInfoModal: false,
    isOpenAppointmentCreateModal: false,
    isOpenCategoryModal: false,
    isOpenServiceModal: false,
    isOpenServicePreviewModal: false,
    isOpenCreateUserModal: false,
    isOpenUpdateUserScheduleModal: false,
    isOpenCreateUserScheduleModal: false,
    isOpenFeedbackModal: false,
    isOpenVoucherCreateModal: false,
    isOpenVoucherInfoModal: false,
    isOpenVoucherRedeemModal: false,
    isOpenConfirmOtpModal: false,
  },
  getters: {},
  mutations: {
    toggleCreateRequestModal(state, data) {
      state.isOpenCreateRequestModal = data;
    },
    toggleDeleteRequestModal(state, data) {
      state.isOpenDeleteRequestModal = data;
    },
    toggleRejectRequestModal(state, data) {
      state.isOpenRejectRequestModal = data;
    },
    toggleApproveRequestModal(state, data) {
      state.isOpenApproveRequestModal = data;
    },
    toggleAppointmentInfoModal(state, data) {
      state.isOpenAppointmentInfoModal = data;
    },
    toggleAppointmentCreateModal(state, data) {
      state.isOpenAppointmentCreateModal = data;
    },
    toggleCategoryModal(state, data) {
      state.isOpenCategoryModal = data;
    },
    toggleServiceModal(state, data) {
      state.isOpenServiceModal = data;
    },
    toggleServicePreviewModal(state, data) {
      state.isOpenServicePreviewModal = data;
    },
    toggleUserCreateModal(state, data) {
      state.isOpenCreateUserModal = data;
    },
    toggleUpdateUserScheduleModal(state, data) {
      state.isOpenUpdateUserScheduleModal = data;
    },
    toggleCreateUserScheduleModal(state, data) {
      state.isOpenCreateUserScheduleModal = data;
    },
    toggleFeedbackModal(state, data) {
      state.isOpenFeedbackModal = data;
    },
    toggleVoucherCreateModal(state, data) {
      state.isOpenVoucherCreateModal = data;
    },
    toggleVoucherInfoModal(state, data) {
      state.isOpenVoucherInfoModal = data;
    },
    toggleVoucherRedeemModal(state, data) {
      state.isOpenVoucherRedeemModal = data;
    },
    toggleConfirmOtpModal(state, data) {
      state.isOpenConfirmOtpModal = data;
    },
  },
  actions: {
    toggleCreateRequestModal({ commit }, data) {
      commit("toggleCreateRequestModal", data);
    },
    toggleDeleteRequestModal({ commit }, data) {
      commit("toggleDeleteRequestModal", data);
    },
    toggleRejectRequestModal({ commit }, data) {
      commit("toggleRejectRequestModal", data);
    },
    toggleApproveRequestModal({ commit }, data) {
      commit("toggleApproveRequestModal", data);
    },
    toggleAppointmentInfoModal({ commit }, data) {
      commit("toggleAppointmentInfoModal", data);
    },
    toggleAppointmentCreateModal({ commit }, data) {
      commit("toggleAppointmentCreateModal", data);
    },
    toggleCategoryModal({ commit }, data) {
      commit("toggleCategoryModal", data);
    },
    toggleServiceModal({ commit }, data) {
      commit("toggleServiceModal", data);
    },
    toggleServicePreviewModal({ commit }, data) {
      commit("toggleServicePreviewModal", data);
    },
    toggleUserCreateModal({ commit }, data) {
      commit("toggleUserCreateModal", data);
    },
    toggleUpdateUserScheduleModal({ commit }, data) {
      commit("toggleUpdateUserScheduleModal", data);
    },
    toggleCreateUserScheduleModal({ commit }, data) {
      commit("toggleCreateUserScheduleModal", data);
    },
    toggleFeedbackModal({ commit }, data) {
      commit("toggleFeedbackModal", data);
    },
    toggleVoucherCreateModal({ commit }, data) {
      commit("toggleVoucherCreateModal", data);
    },
    toggleVoucherInfoModal({ commit }, data) {
      commit("toggleVoucherInfoModal", data);
    },
    toggleVoucherRedeemModal({ commit }, data) {
      commit("toggleVoucherRedeemModal", data);
    },
    toggleConfirmOtpModal({ commit }, data) {
      commit("toggleConfirmOtpModal", data);
    },
  },
};

export default ModalStore;
