<template>
  <select v-model="$i18n.locale">
    <option :key="index" :value="item.value" v-for="(item, index) in options">
      {{ item.image }}
      <span v-if="withText ?? true">{{ item.title }}</span>
    </option>
  </select>
</template>

<script>
import { mapActions } from "vuex";
import { ref } from "vue";

export default {
  name: "LocaleSwitcher",
  props: ["withText"],
  setup() {
    const options = ref([
      {
        title: "English",
        image: "🇺🇸",
        value: "en",
      },
      {
        title: "Українська",
        image: "🇺🇦",
        value: "uk",
      },
    ]);

    return { options };
  },
  watch: {
    "$i18n.locale"(newV, oldV) {
      if (newV !== oldV) {
        this.setLanguage(newV);
      }
    },
  },
  methods: {
    ...mapActions({
      setLanguage: "lang/setLanguage",
    }),
  },
};
</script>
